import { Action } from '../config_actions'
import { Apis } from '../config_apis'
//import { Mutation } from '../config_mutations'
import axios from 'axios'
//import cookies from 'vue-cookies'
//import moment from 'moment'

export default {
    namespaced: false,

    state: {
    },

    mutations: {

    },

    actions: {

        /**
        * Downloads images list
        */
        [Action.IMAGES_GET_LIST](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.IMAGES_GET_LIST,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
    }
}