import { Action } from '../config_actions'
import { Apis } from '../config_apis'
//import { Mutation } from '../config_mutations'
import axios from 'axios'
//import cookies from 'vue-cookies'
//import moment from 'moment'

export default {
    namespaced: false,

    state: {
    },

    mutations: {

    },

    actions: {

        /**
         * 
         */
        [Action.COURSE_ADD_PDF](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.COURSE_ADD_PDF.replace('{id}', payload.cid).replace('{mid}', payload.mid),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Downloads COURSES
         */
        [Action.COURSE_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.COURSE_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Downloads COURSE by ID
         */
        [Action.COURSE_GET](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.COURSE_GET.replace('{id}', payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Add new COURSE
         */
        [Action.COURSE_CREATE](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.COURSE_CREATE,
                    headers: { Authorization: "Bearer " + token },
                    data: payload,
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
         * Delete COURSE
         */
        [Action.COURSE_DELETE](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: Apis.COURSE_DELETE.replace('{id}', payload),
                    headers: { Authorization: "Bearer " + token }
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
         * Update COURSE
         */
        [Action.COURSE_MODIFY](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'put',
                    url: Apis.COURSE_MODIFY.replace('{id}', payload.id),
                    headers: { Authorization: "Bearer " + token },
                    data: payload
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
        * Downloads teacher COURSES
        */
        [Action.COURSE_GET_TEACHER_COURSES](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.COURSE_GET_TEACHER_COURSES,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
        * Downloads student courses
        */
        [Action.COURSE_GET_STUDENT_COURSES](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.COURSE_GET_STUDENT_COURSES,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
        * Downloads multiple courses
        */
        [Action.COURSE_GET_MULTIPLE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.COURSE_GET_MULTIPLE,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        }
    }
}