<template>
  <div>
    <b-dropdown aria-role="list" position="is-bottom-left">
      <b-tooltip :label="t('config.regulations')" position="is-bottom" slot="trigger">
        <span class="hand">
          <b-icon type="is-secondary" icon="information-outline"></b-icon>
        </span>
      </b-tooltip>

      <b-dropdown-item
        href="https://www.ka.edu.pl/gfx/ksw/userfiles/adminwm/dla-studentow/organizacja-roku/regulamin-studiow-od-1-10-2020.pdf"
        target="_blank"
        aria-role="listitem"
        class="is-size-6"
      >
        <b-icon icon="notebook" size="is-small" class="mr-2"></b-icon>{{t('config.studyRegulations')}}
      </b-dropdown-item>
      <b-dropdown-item
        href="https://bipfiles.ka.edu.pl/statut-krakowskiej-akademii-2020a.pdf"
        target="_blank"
        aria-role="listitem"
        class="is-size-6"
      >
        <b-icon icon="notebook" size="is-small" class="mr-2"></b-icon>{{t('config.statuteAcademy')}}
      </b-dropdown-item>
      <b-dropdown-item
        href="info/AFM-instrukcja-student.pdf"
        target="_blank"
        aria-role="listitem"
        class="is-size-6"
        v-if="isStudent"
      >
        <b-icon icon="notebook" size="is-small" class="mr-2"></b-icon>{{t('config.instruction')}}
      </b-dropdown-item>
      <b-dropdown-item
        href="info/AFMinstrukcjawykładowca.pdf"
        target="_blank"
        aria-role="listitem"
        class="is-size-6"
        v-else
      >
        <b-icon icon="notebook" size="is-small" class="mr-2"></b-icon>{{t('config.instruction')}}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "RegulationInfo",
  data() {
    return {};
  },

  computed: {

    session() {
      return this.$store.state.auth.session;
    },

    isAdmin() {
      if (this.session && this.session.roles.includes("Admin")) return true;
      else return false;
    },

    isTeacher() {
      if (this.session && this.session.roles.includes("Teacher")) return true;
      else return false;
    },

    isStudent() {
      if (this.session && this.session.roles.includes("Student")) return true;
      else return false;
    }
  },
};
</script>

<style scoped lang="scss">
</style>
