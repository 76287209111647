export default {
    data: function () {
        return {
            mRoleTypes: [
                {
                    name: this.t('userRole.admin'),//"Administrator",
                    id: 0,
                    roles:["Admin"],
                },
                {
                    name: this.t('userRole.teacherAdmin'),// "Administrator/Dydaktyk",
                    id: 1,
                    roles:["Admin", "Teacher"],
                },
                {
                    name: this.t('userRole.teacher'),// "Dydaktyk",
                    id: 2,
                    roles:["Teacher"],
                },
                {
                    name: this.t('userRole.student'),// "Student",
                    id: 3,
                    roles:["Student"],
                }
            ]
        }
    },

    methods: {
        mGetRoleTypesName(roles){
            let role = this.mRoleTypes.find(c => this.arrayEquals(roles,c.roles))
            if (role) return role.name
            else return `??? [${roles}]`
        },

        arrayEquals(a, b) {
            return Array.isArray(a) &&
              Array.isArray(b) &&
              a.length === b.length &&
              a.every((val) => b.includes(val));
          }
    }
}