import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import { Mutation } from '../config_mutations'
import axios from 'axios'
import cookies from 'vue-cookies'
import moment from 'moment'

export default {
    namespaced: false,

    state: {
        session: null,
        users: null
    },

    mutations: {
        /** Updates time to now */
        [Mutation.AUTH_START_SESSION](state, payload) {
            if (payload.isNew) {
                state.session = payload
                state.session.isNew = false
                state.session.ends = moment().add(payload.expires - 3, 'seconds')
                state.session.activity = 2700
                var cookieExpiry = payload.expires.toString() + 's'
                cookies.set("AFM_SESSION", payload, cookieExpiry);
            }
            else {
                state.session = payload
            }
        },

        /** Updates time to now */
        [Mutation.AUTH_END_SESSION](state) {
            state.session = null
        },

        /** Updates time to now */
        [Mutation.AUTH_RESET_ACTIVITY](state) {
            state.session.activity = 2700
        },

        /** Updates time to now */
        [Mutation.AUTH_LOWER_ACTIVITY](state) {
            state.session.activity--
        },

        [Mutation.USERS_SET](state,payload){
            state.users = payload
        }
    },

    actions: {

        /** Request password reset mail. */
        [Action.AUTH_RESET_REQUEST](context, email) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'get',
                    url: Apis.AUTH_PASSWORD_RESET_REQUEST.replace('{email}', email)
                }).then(() => {
                    resolve();
                }).catch(error => { reject(error) });
            })
        },

        /** Atttempts password change with reset token. */
        [Action.AUTH_RESET_PASSWORD](context, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    data: payload,
                    url: Apis.AUTH_PASSWORD_RESET
                }).then(() => {
                    resolve();
                }).catch(error => { reject(error) });
            })
        },



        [Action.STATS_SEND_FEEDBACK](context, payload) {
            return new Promise((resolve, reject) => {
                // let token = context.rootState.identity.session.token;
                // if (token != null) {
                axios({
                    method: 'post',
                    url: Apis.STATS_SEND_FEEDBACK,
                    data: payload,
                    //  headers: { Authorization: "Bearer " + token }
                }).then((response) => {
                    resolve(response);
                }).catch(error => { reject(error) });
                // } else reject('401')
            })
        },

        /**
         * Login user
         */
        [Action.AUTH_LOGIN](context, payload) {
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.AUTH_LOGIN,
                    data: payload,
                }).then(response => {
                    let userData = response.data
                    userData.isNew = true
                    context.commit(Mutation.AUTH_START_SESSION, userData)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
         * Register user
         */
        [Action.AUTH_ADD_USER](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.AUTH_REGISTER,
                    headers: { Authorization: "Bearer " + token },
                    data: payload,
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
         * Logout user
         */
        [Action.AUTH_RENEW](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.AUTH_RENEW,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        let userData = response.data
                        userData.isNew = true
                        context.commit(Mutation.AUTH_START_SESSION, userData)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Logout user
         */
        [Action.AUTH_LOGOUT](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.AUTH_LOGOUT,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.AUTH_END_SESSION)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        

        /**
         * Logout user
         */
        [Action.AUTH_LOGOUT_USER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.AUTH_LOGOUT_USER.replace('{email}', payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Download users list
         */
        [Action.AUTH_GET_USERS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.AUTH_ADMIN_USERS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.USERS_SET,response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * DUMMY ACTION
         */
        [Action.xxxx](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.AUTH_LOGIN,
                        data: payload,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.AUTH_START_SESSION, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Get user by id
         */
        [Action.AUTH_GET_USER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.AUTH_GET_USER.replace('{id}', payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Get name for userId
         */
        [Action.AUTH_GET_USER_NAME](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.AUTH_GET_USER_NAME.replace('{id}', payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },



        /**
         * Get full info user by id
         */
        [Action.AUTH_GET_FULLINFO_USER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.AUTH_GET_FULLINFO_USER.replace('{id}', payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        [Action.AUTH_GET_ME](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.AUTH_GET_ME,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Update password
         */
        [Action.AUTH_CHANGE_PASSWORD](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.AUTH_CHANGE_PASSWORD,
                    headers: { Authorization: "Bearer " + token },
                    data: payload,
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
         * Update user
         */
        [Action.AUTH_MODIFY_USER](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.AUTH_MODIFY_USER,
                    headers: { Authorization: "Bearer " + token },
                    data: payload,
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
         * Remove user
         */
        [Action.AUTH_DELETE_USER](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.AUTH_DELETE_USER.replace('{id}', payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
    }
}