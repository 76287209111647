import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import { Mutation } from '../config_mutations'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        students: null
    },

    mutations:{
        [Mutation.USER_STUDENTS_SET](state,payload){
            state.students = payload
        }

    },

    actions:{

         /**
         * Downloads students
         */        
        [Action.STUDENT_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.STUDENT_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.USER_STUDENTS_SET,response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },        
    }
}