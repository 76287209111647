/**
 * General APIs configuration.
 */


export const SERVER = 'https://afm.digitmed.pl'
//export const SERVER = 'https://gwdl.digitmed.pl'
export const SERVICE_STATS = 'https://st.digitmed.pl'

export const Apis = {

    // ****************************************************************************************
    // AUTH SERVICE
    // ****************************************************************************************

    SYNC_DEMO: SERVER + '/demo',

    AUTH_LOGIN: SERVER + '/api/Identity',
    AUTH_LOGOUT: SERVER + '/api/Identity/logout',
    AUTH_RENEW: SERVER + '/api/Identity/renew',

    AUTH_ADMIN_USERS: SERVER + '/api/identity',
    AUTH_DELETE_USER: SERVER + '/api/Identity/remove/{id}',
    AUTH_GET_FULLINFO_USER: SERVER + '/api/Identity/user/{id}',
    AUTH_GET_USER: SERVER + '/api/Identity/userdata/{id}',
    AUTH_GET_USER_NAME: SERVER + '/api/Identity/name/{id}',
    AUTH_GET_ME: SERVER + '/api/Identity/me',
    AUTH_MODIFY_USER: SERVER + '/api/Identity/modify',

    AUTH_REGISTER: SERVER + '/api/identity/add',
    AUTH_ROLES: SERVER + '/api/identity/roles',
    AUTH_GET_ROLE: SERVER + '/api/Identity/role/{id}',
    AUTH_TOKEN_RENEW: SERVER + '/api/identity/renew',
    AUTH_CHANGE_PASSWORD: SERVER + '/api/identity/updatepassword',
    AUTH_PASSWORD_RESET_REQUEST: SERVER + '/api/identity/resetpassword/{email}',
    AUTH_LOGOUT_USER: SERVER + '/api/identity/logoutuser/{email}',
    AUTH_PASSWORD_RESET: SERVER + '/api/identity/resetpassword',

    STATS_SEND_FEEDBACK: SERVICE_STATS + '/api/workerfeedback',

    STUDENT_GET_ALL: SERVER + '/api/Identity/students',
    TEACHER_GET_ALL: SERVER + '/api/Identity/teachers',

    IMAGES_GET_LIST: SERVER + '/api/Images',



    // ****************************************************************************************
    // DATA SERVICE
    // ****************************************************************************************

    AFM_CONFIG_GET: SERVER + '/api/AfmConfig',
    AFM_CONFIG_CREATE: SERVER + '/api/AfmConfig',

    DEEPZOOM_CONFIG_GET: SERVER + '/api/DeepZoomConfig',
    DEEPZOOM_CONFIG_CREATE: SERVER + '/api/DeepZoomConfig',
    DEEPZOOM_CONFIG_GROUP: SERVER + '/api/DeepZoomConfig/Group',

    MEDIA_GET_ALL: SERVER + '/api/MediaFile',
    MEDIA_GET: SERVER + '/api/MediaFile/{id}',
    MEDIA_UPLOAD: SERVER + '/api/MediaFile',
    MEDIA_DELETE: SERVER + '/api/MediaFile/{id}',
    MEDIA_UPDATE: SERVER + '/api/MediaFile/{id}',
    MEDIA_GET_MY: SERVER + '/api/MediaFile/Owned',
    MEDIA_GET_IMAGE: SERVER + '/api/MediaFile/File/{id}',


    CLASSROOM_GET: SERVER + '/api/Classroom',
    CLASSROOM_BY_ID: SERVER + '/api/Classroom/{id}',
    CLASSROOM_MODIFY: SERVER + '/api/Classroom/{id}',
    CLASSROOM_CREATE: SERVER + '/api/Classroom',
    CLASSROOM_DELETE: SERVER + '/api/Classroom/{id}',
    CLASSROOM_GET_TEACHER_CLASS: SERVER + '/api/Classroom/MyTeacherClassrooms',
    CLASSROOM_GET_STUDENT_CLASS: SERVER + '/api/Classroom/MyStudentClassrooms',
    CLASSROOMS_FROM_USEREXAMS: SERVER + '/api/Classroom/ClassroomsFromUserExams',
    CLASSROOM_GET_MYCLASSROOM_FOR_CLASSID: SERVER + '/api/Classroom/MyClassroomsFromCourse/{id}',

    EXAM_GET: SERVER + '/api/Exam',
    EXAM_BY_ID: SERVER + '/api/Exam/{id}',
    EXAM_MODIFY: SERVER + '/api/Exam/{id}',
    EXAM_CREATE: SERVER + '/api/Exam',
    EXAM_DELETE: SERVER + '/api/Exam/{id}',

    QUESTION_GET: SERVER + '/api/Question',
    QUESTION_BY_ID: SERVER + '/api/Question/{id}',
    QUESTION_MODIFY: SERVER + '/api/Question/{id}',
    QUESTION_CREATE: SERVER + '/api/Question',
    QUESTION_DELETE: SERVER + '/api/Question/{id}',

    USEREXAM_GET: SERVER + '/api/UserExam',
    USEREXAM_BY_ID: SERVER + '/api/UserExam/{id}',
    USEREXAM_MODIFY: SERVER + '/api/UserExam/{id}',
    USEREXAM_CREATE: SERVER + '/api/UserExam',
    USEREXAM_DELETE: SERVER + '/api/UserExam/{id}',
    USEREXAM_GET_BY_CLASSID: SERVER + '/api/UserExam/GetByClassId/{id}',
    USEREXAM_GET_MY_EXAMS: SERVER + '/api/UserExam/GetMyExams',
    USEREXAM_GET_MY_EXAMS_IN_CLASS: SERVER + '/api/UserExam/GetMyExamInClass/{id}',

    COURSE_GET_ALL: SERVER + '/api/Course',
    COURSE_GET: SERVER + '/api/Course/{id}',
    COURSE_CREATE: SERVER + '/api/Course',
    COURSE_MODIFY: SERVER + '/api/Course/{id}',
    COURSE_DELETE: SERVER + '/api/Course/{id}',
    COURSE_ADD_PDF: SERVER + '/api/Course/Pdf/{id}/{mid}',
    COURSE_GET_TEACHER_COURSES: SERVER + '/api/Course/MyTeacherCourses',
    COURSE_GET_STUDENT_COURSES: SERVER + '/api/Course/MyStudentCourses',
    COURSE_GET_MULTIPLE: SERVER + '/api/Course/Multiple'

}