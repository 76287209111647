/**
 * This configures validation with VeeValidate.
 * Messages returned from here are actually paths to localization strings.
 */

import { extend } from 'vee-validate';

// Import built in validators.
import { required, digits, email, alpha, alpha_num, between, confirmed, numeric, max, min } from "vee-validate/dist/rules";

extend('required', { ...required, message: 'validation.required' })
extend('alpha', { ...alpha, message: 'validation.alpha' })
extend('digits', { ...digits, message: 'validation.digits'  })
extend('email', { ...email, message: 'validation.email'  })
extend('alpha_num', { ...alpha_num, message: 'validation.alphaNum' })
extend('between', { ...between, message: 'validation.between'  })
extend('confirmed', { ...confirmed, message: 'validation.confirmed' })
extend('numeric', { ...numeric, message: 'validation.numeric' })
extend('min', { ...min, message: 'validation.min' })
extend('max', { ...max, message: 'validation.max' })//this.t('validation.max',{'length': length})

// Only base letters and numbers (regular alpha_num allows polish letters)
extend('alpha_num_base', {
    validate: (value => {
        let regex = /^[a-zA-Z0-9]*$/
        return regex.test(value)
    }),
    message: 'validation.alphaNumBase'
})

// PESEL validation
extend('pesel', {
    validate: (value => {
        let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
        let sum = 0;
        let controlNumber = parseInt(value.substring(10, 11));

        for (let i = 0; i < weight.length; i++) {
            sum += (parseInt(value.substring(i, i + 1)) * weight[i]);
        }
        sum = sum % 10;
        return (10 - sum) % 10 === controlNumber;
    }),
    message: 'Nieprawidłowy format numeru PESEL'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('userpass', {
    validate: (value => {
        let regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/
        return regex.test(value)
    }),
    message: 'validation.userpass'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('alpha_space_slash', {
    validate: (value => {
        let regex = /^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\s\-/]*$/
        return regex.test(value)
    }),
    message: 'validation.alphaSpaceSlash'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('surname', {
    validate: (value => {
        let regex = /^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ-]*$/
        return regex.test(value)
    }),
    message: 'validation.surname'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('alpha_space', {

    validate: (value => {
        let regex = /^[a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ\s]*$/
        return regex.test(value)
    }),

    message: 'validation.alphaSpace'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('alpha_num_space_slash', {

    validate: (value => {
        let regex = /^[a-zA-Z0-9żźćńółęąśŻŹĆĄŚĘŁÓŃ\s\-/]*$/
        return regex.test(value)
    }),

    message: 'validation.alphaNumSpaceSlash'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('alpha_num_slash', {

    validate: (value => {
        let regex = /^[a-zA-Z0-9żźćńółęąśŻŹĆĄŚĘŁÓŃ\-/]*$/
        return regex.test(value)
    }),

    message: 'validation.alphaNumSlash'
})

// Password must contain at least one uppercase, one lowercase, digit and a special symbol.
extend('zip_code', {

    validate: (value => {
        let regex = /^\d\d-\d\d\d$/
        return regex.test(value)
    }),

    message: 'validation.zipCode'
})

// Telephone validation.
// TODO: More complex werification :)
extend('telephone', {

    validate: (value => {
        let regex = /^[0-9]{9,10}$/
        return regex.test(value)
    }),

    message: 'validation.telephone'
})