import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import { Mutation } from '../config_mutations'
import axios from 'axios'

export default {
    namespaced: false,

    state: {
        myFiles: null,
        files: null
    },

    mutations: {
        [Mutation.MEDIA_SET_MY](state, payload) {
            state.myFiles = payload
        },

        [Mutation.MEDIA_SET](state, payload) {
            state.files = payload
        }

    },

    actions: {

        /**
         * Downloads MEDIA
         */
        [Action.MEDIA_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MEDIA_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MEDIA_SET, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Downloads MEDIA
         */
        [Action.MEDIA_GET_MY](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MEDIA_GET_MY,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.MEDIA_SET_MY, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Downloads MEDIA
         */
        [Action.MEDIA_GET](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.MEDIA_GET.replace('{id}', payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Downloads MEDIA
         */
        [Action.MEDIA_GET_IMAGE](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        responseType: 'blob',
                        url: Apis.MEDIA_GET_IMAGE.replace('{id}', payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Update MEDIA
         */
        [Action.MEDIA_UPDATE](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'put',
                    url: Apis.MEDIA_UPDATE.replace('{id}', payload.id),
                    headers: { Authorization: "Bearer " + token },
                    data: payload,
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
         * Delete question
         */
        [Action.MEDIA_DELETE](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: Apis.MEDIA_DELETE.replace('{id}', payload),
                    headers: { Authorization: "Bearer " + token }
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },


        /**
         * MEDIA UPLOAD
         */
        [Action.MEDIA_UPLOAD](context, form) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    const payload = new FormData();
                    payload.append('description', form.description)
                    payload.append('type', form.type)
                    payload.append('uploadedFile', form.upladedFile)
                    payload.append('tags', form.tags)
                    axios({
                        method: 'post',
                        url: Apis.MEDIA_UPLOAD,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        }

    }
}