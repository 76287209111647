import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import axios from 'axios'
import { Mutation } from '../config_mutations';

export default {
    namespaced: false,

    state: {
        config: null
    },

    mutations:{

        [Mutation.AFM_CONFIG_SET](state,payload){
            state.config = payload;
        }

    },

    actions:{
 
         /**
          * Get AFM config
          */
         [Action.AFM_CONFIG_GET](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.AFM_CONFIG_GET,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.AFM_CONFIG_SET,response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },   
        
        
        /**
         * Create AFM config
         */
        [Action.AFM_CONFIG_CREATE](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.AFM_CONFIG_CREATE,
                    headers: { Authorization: "Bearer " + token },
                    data: payload,
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },
    }
}