<template>
  <div id="app" :style="getBackground">
    <SiteNavigation v-if="session" :activity="activity"></SiteNavigation>
    <div style="min-height: calc(100vh - 5.6rem)">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <footer v-if="session">
      <p class="is-size-7 pt-1 pl-5">
        <span class="has-text-weight-bold has-text-light">{{version}}</span>
        <span><a style="color:white" class="pl-2" target="_blank" href="https://www.digitmed.pl">www.digitmed.pl</a></span>
      </p>
    </footer>
  </div>
</template>

<script>
import SiteNavigation from './components/navigation/SiteNavigation'
import { Mutation } from './store/config_mutations'
import moment from 'moment'
import { Action } from './store/config_actions'
import Commons from './mixins/commons'

export default {


  name: 'App',
  mixins: [Commons],
  components: {
    SiteNavigation
  },

  data: function () {
    return {
      version: 'v1.220623.0T',
      timer: null,
      activity: 0
    }
  },

  computed: {
    session() { return this.$store.state.auth.session },
    currentRouteName() { return this.$route.name },

    getBackground() {
      if (this.currentRouteName == 'login') {
        return 'background-image: url(mbx.jpg); background-position: center center; background-size: cover; min-height: 100vh;'
      }
      else {
        return 'background-image: url(ws.jpg);background-attachment: fixed; background-position: center center; background-size: cover; min-height: 100vh;'
      }
    }
  },

  created() {
    // Check if auth cookie exists.
    let sess = this.checkAuthCookie()


    // Delay session check by one tick, otherwise this can trigger before vuex updates itself with cookie.
    this.$nextTick(() => {
      if (sess) {
        //this.$router.push({ name: 'dashboard' })
      }
      else {
        // this.$router.push({ name: 'login' })
      }
    })
  },

  mounted() {
    // Setup activity listeners.
    document.removeEventListener('click', () => { })
    document.removeEventListener('keydown', () => { })

    setTimeout(() => document.addEventListener('click', this.itemClicked), 100)
    setTimeout(() => document.addEventListener('keydown', this.itemClicked), 100)

    clearInterval(this.authInterval)
    let thisVue = this

    this.authInterval = setInterval(function () {
      let cookie = thisVue.$cookies.get('AFM_SESSION');
      if (cookie) {
        let difference = moment(cookie.ends).diff(moment(), 'seconds')

        if (difference < 1) {
          thisVue.$cookies.remove('AFM_SESSION');
          thisVue.$store.commit(Mutation.AUTH_END_SESSION)

          if (thisVue.$route.name != 'login') {
            thisVue.$router.push({ name: 'login' })
          }
          else {
            // Ok, already in login page
          }
        }
        else if (difference < 60) {
          thisVue.renew()
        }
        else {
          thisVue.timer = difference
        }
      }
      else {
        let allowedAnonymous = ['login', 'password-reset', 'password-request']

        if (!allowedAnonymous.includes(thisVue.$route.name)) {
          thisVue.$store.commit(Mutation.AUTH_END_SESSION)
          thisVue.$router.push({ name: 'login' })
        }
        else {
          // Ok, already in login page
        }
      }


      if (thisVue.session) {
        this.activity = thisVue.session.activity
        if (thisVue.session.activity < 60) {
          thisVue.mDanger("Brak aktywności, zostaniesz wylogowany za " + this.session.activity + " sekund!")
        }

        if (thisVue.session.activity < 1) {
          thisVue.logout()
        }

        thisVue.$store.commit(Mutation.AUTH_LOWER_ACTIVITY)
      }
      else {
        this.activity = 0
      }






    }.bind(this), 1000);
  },

  methods: {

    /** Updates activity timer. */
    itemClicked() {
      if (this.session) {
        this.$store.commit(Mutation.AUTH_RESET_ACTIVITY)
      }
    },

    renew() {
      this.$store
        .dispatch(Action.AUTH_RENEW)
        .then(() => {
          //this.mDangerSnack('RENEW')
        })
        .catch(error => {
          //console.log(error)
          this.mDangerSnack(error);
        });
    },


    checkAuthCookie() {
      let cookieSession = this.$cookies.get('AFM_SESSION');
      if (cookieSession) {
        this.$store.commit(Mutation.AUTH_START_SESSION, cookieSession)
        return cookieSession
      }
    },


    logout() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.AUTH_LOGOUT)
        .then(() => {
          this.isLoading = false;
          this.$cookies.remove("AFM_SESSION");
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          this.isLoading = false;
          this.mDangerSnack(error.toString());
        });
    },
  }
}
</script>
























<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Takie tam modyfikacje, żeby utrzymać podobny styl z głównym systemem
$primary: #163572;
$primary-invert: findColorInvert($primary);
$secondary: #ffcc00;
$secondary-invert: #163572;

$danger: #990000;
$danger-invert: findColorInvert($danger);
$success: #006600;
$success-invert: findColorInvert($success);

$kin: #22324c;
$kin-invert: findColorInvert($kin);

$input-background-color: #f6f6fc;
$input-shadow: none;
$input-border-color: #d6d6d6;

$toast-opacity: 1;

$control-radius: 5px;
$radius: 5px;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
  (
    "white": (
      $white,
      $black,
    ),
    "black": (
      $black,
      $white,
    ),
    "light": (
      $light,
      $light-invert,
    ),
    "dark": (
      $dark,
      $dark-invert,
    ),
    "secondary": (
      $secondary,
      $secondary-invert,
    ),
    "primary": (
      $primary,
      $primary-invert,
      $primary-light,
      $primary-dark,
    ),
    "kin": (
      $kin,
      $kin-invert,
    ),
    "link": (
      $link,
      $link-invert,
      $link-light,
      $link-dark,
    ),
    "info": (
      $info,
      $info-invert,
      $info-light,
      $info-dark,
    ),
    "success": (
      $success,
      $success-invert,
      $success-light,
      $success-dark,
    ),
    "warning": (
      $warning,
      $warning-invert,
      $warning-light,
      $warning-dark,
    ),
    "danger": (
      $danger,
      $danger-invert,
      $danger-light,
      $danger-dark,
    ),
  ),
  $custom-colors
);

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

$navbar-breakpoint: $widescreen;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  //font-weight: 500;
}

.card {
  color: #000000;
}

.subtitle {
  color: #000000;
}

.table {
  color: #000000;
}

.no-radius {
  border-radius: 0px !important;
}

.tabs li a {
  font-weight: 600;
  padding-top: 0.55rem;
  font-size: 1rem !important;
  text-transform: uppercase;
}

.tabs li.is-active {
  background-color: $secondary;
  font-weight: 600;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.skyColor {
  background-color: #abd8ee;
  border-radius: 5px;
}

.navbar-brand a {
  padding: 0 !important;
}

.navbar-item img {
  max-height: 50px !important;
}

.one .tabs li.is-active a {
  background-color: $white;
  font-weight: 600;
}

.tabs {
  font-size: 1.25rem;
}

.has-dropdown > a {
  text-transform: uppercase;
  font-weight: 600;
}

.is-small > .icon,
.is-small > .sic-small {
  font-size: 1.2rem !important;
}

.is-small {
  vertical-align: middle;
}

.addon .is-small {
  font-size: 0.65rem !important;
}

.addon .is-small > .icon {
  margin-top: -0.1rem;
  font-size: 1rem !important;
}

.is-small > span > .icon {
  font-size: 1.2rem;
  padding: 0 !important;
}

.control.has-icons-left.is-small .icon {
  color: inherit !important;
  height: 1.9rem;
  width: 2.05rem;
}

.row-one-height{
  height: 10rem !important;
}
</style>
