// Common useful methods
// MC.

import moment from 'moment'

export default {
  data: function () {
    return {

      mApiProblemsPL: [
        {
          internalCode: 0,
          message: "Ogólny problem"
        }
      ]
    }
  },

  methods: {

    mDateTimeFull(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss.SSS')
    },

    mDateTimeSeconds(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },

    mNow() {
      return moment().format('YYYY-MM-DDTHH:mm:ss.SSS')
    },

    mNowPlusHours(added) {
      return moment().add(added, 'hours').format('YYYY-MM-DDTHH:mm:ss.SSS')
    },

    mNowPlusMinutes(added) {
      return moment().add(added, 'minutes').format('YYYY-MM-DDTHH:mm:ss.SSS')
    },

    mDatePlusMinutes(dateString, added) {
      return moment(dateString).add(added, 'minutes').format('YYYY-MM-DDTHH:mm:ss')
    },

    mNowPlusDays(added) {
      return moment().add(added, 'days').format('YYYY-MM-DDTHH:mm:ss.SSS')
    },

    mDateTimeRaw(item) {
      return moment(item).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
    },

    mDateTimeRaw2(item) {
      return moment(item).format('YYYY-MM-DDTHH:mm:ss')
    },

    mNowDate() {
      return moment().format('YYYY-MM-DD')
    },

    mDateTime(dateString) {
      return moment(dateString).format('YYYY-MM-DD HH:mm')
    },
    mDate(dateString) {
      return moment(dateString).format('YYYY-MM-DD')
    },

    mTime(dateString) {
      return moment(dateString).format('HH:mm')
    },

    mDaysDiff(dateString) {
      return moment().diff(moment(dateString), 'days')
    },

    mHours(dateString) {
      return moment(dateString).format('HH')
    },

    mMinutes(dateString) {
      return moment(dateString).format('mm')
    },

    mComparerDate: function (firstDate, secondDate) {
      var first = moment(firstDate)
      var second = moment(secondDate)
      return second.isAfter(first)
    },

    mBeforeNow(dateString) {
      var first = this.mDateTimeFull(dateString)
      var now = moment()
      return now.isBefore(first)
    },

    mAfterNow(dateString) {
      var first = this.mDateTimeFull(dateString)
      var now = moment()
      return now.isAfter(first)
    },

    todo() {
      this.snackbar('W budowie :)')
    },

    mHasProp(prop) {
      if (prop) return true
      else return false
    },

    mCopy(mainObj, targetObj) {
      let key;

      for (key in mainObj) {
        targetObj[key] = mainObj[key]; // copies each property to the objCopy object
      }
    },

    areArraysEqual(arrayOne, arrayTwo) {
      return JSON.stringify(arrayOne) === JSON.stringify(arrayTwo)
    },

    mSuccess(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
        queue: false,
        position: 'is-bottom'
      })
    },

    mDanger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
        queue: false,
        position: 'is-top',
        duration: 4000,
      })
    },

    mToast(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-dark',
        queue: false,
        position: 'is-bottom'
      })
    },

    // Show bad snackbar.
    mSnack(msg) {
      //this.toast(msg)
      this.$buefy.snackbar.open({ message: msg, queue: false, type: 'is-info' })
    },

    // Show bad snackbar.
    mDangerSnack(msg) {
      //this.dangerToast(msg)
      this.$buefy.snackbar.open({ message: msg, queue: false, type: 'is-danger' })
    },

    // Show good snackbar.
    mSuccessSnack(msg) {
      //this.successToast(msg)
      this.$buefy.snackbar.open({ message: msg, queue: false, type: 'is-success' })
    },

    mInfoSnack(msg) {
      this.$buefy.dialog.alert({
        type: 'is-danger',
        message: msg
      })
    },

    dangerToast(message) {
      this.$buefy.toast.open({
        message: message.toString(),
        type: 'is-danger',
        queue: false,
        position: 'is-top',
        duration: 4000,
      })
    },

    mGetApiProblem(internalCode) {
      let result = this.mApiProblemsPL.find(s => s.internalCode === internalCode)
      return result ? result.message : `Błąd (${internalCode})`
    },

    apiProblem(error) {
      //console.log(error.response)
      if (error.response) {
        if (error.response.data.internalCode) {
          switch (error.response.data.internalCode) {
            case 6006:
              this.dangerSnackbar(`apiProblems.${error.response.data.internalCode}` + ` (${error.response.data.internalCode})`)
              break
            case 6007:
              this.dangerSnackbar(`apiProblems.${error.response.data.internalCode}` + ` (${error.response.data.internalCode})`)
              break
            default:
              this.dangerToast(`apiProblems.${error.response.data.internalCode}` + ` (${error.response.data.internalCode})`)
              break
          }
        } else if (error.response.data.status) {
          if (error.response.data.status === 401) {
            this.dangerToast('Niepoprawne dane lub użytkownik zalogowany w innym oknie')
          }
          else {
            this.dangerToast(`Wystąpił problem > ${error.response.data.status.toString()}`)
          }
        }
        else {
          if (typeof error.response.data === 'string' || error.response.data instanceof String)
            this.dangerSnackbar(error.response.data)
          else
            this.dangerToast(this.mGetApiProblem(0))
        }
      }
      else {
        if (error === '401') {
          this.dangerToast('Brak autoryzacji')
        }
        else {
          this.dangerToast(`Wystąpił problem : ${error.toString()}`)
        }
      }
    },
  }
}
