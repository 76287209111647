import Vue from 'vue'

// Buefy with local icons.
import Buefy from 'buefy'
import '@mdi/font/css/materialdesignicons.css'
Vue.use(Buefy)

// Languages handler - enables 'ml' object in Vue.
import './localization/languages'

// Cookies!
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// Validation rules.
import './validation/rules'

// Translator helper for easier translations. This adds global t() function for translation.
import TranslatorHelper from './mixins/translator'
Vue.mixin(TranslatorHelper)

Vue.config.productionTip = false

import App from './App.vue'
import router from './router/router'
import store from './store/store'

new Vue({ router, store, render: h => h(App) }).$mount('#app')
