export const Action = {
    AUTH_LOGIN: 'AUTH_LOGIN',
    AUTH_LOGOUT: 'AUTH_LOGOUT',
    AUTH_RENEW: 'AUTH_RENEW',
    AUTH_GET_USERS: 'AUTH_GET_USERS',
    AUTH_ADD_USER: 'AUTH_ADD_USER',
    AUTH_RESET_REQUEST: 'AUTH_RESET_REQUEST',
    AUTH_RESET_PASSWORD: 'AUTH_RESET_PASSWORD',

    AUTH_CHANGE_PASSWORD: 'AUTH_CHANGE_PASSWORD',

    STATS_SEND_FEEDBACK: 'STATS_SEND_FEEDBACK',

    //AFM_CONFIG
    AFM_CONFIG_GET: 'AFM_CONFIG_GET',
    AFM_CONFIG_CREATE: 'AFM_CONFIG_CREATE',

    //DEEPZOOM_CONFIG
    DEEPZOOM_CONFIG_GET: 'DEEPZOOM_CONFIG_GET',
    DEEPZOOM_CONFIG_CREATE: 'DEEPZOOM_CONFIG_CREATE',
    DEEPZOOM_CONFIG_GROUP: 'DEEPZOOM_CONFIG_GROUP',

    //MEDIA
    MEDIA_GET_ALL: 'MEDIA_GET_ALL',
    MEDIA_GET: 'MEDIA_GET',
    MEDIA_UPLOAD: 'MEDIA_UPLOAD',
    MEDIA_UPDATE: 'MEDIA_UPDATE',
    MEDIA_DELETE: 'MEDIA_DELETE',
    MEDIA_GET_MY:'MEDIA_GET_MY',
    MEDIA_GET_IMAGE: 'MEDIA_GET_IMAGE',

    //USER
    AUTH_GET_USER: 'AUTH_GET_USER',
    AUTH_GET_FULLINFO_USER: 'AUTH_GET_FULLINFO_USER',
    AUTH_GET_USER_NAME: 'AUTH_GET_USER_NAME',
    AUTH_GET_ME: 'AUTH_GET_ME',
    AUTH_MODIFY_USER: 'AUTH_MODIFY_USER',
    AUTH_DELETE_USER: 'AUTH_DELETE_USER',
    AUTH_LOGOUT_USER: 'AUTH_LOGOUT_USER',

    STUDENT_GET_ALL: 'STUDENT_GET_ALL',
    TEACHER_GET_ALL: 'TEACHER_GET_ALL',
    TEACHER_GET_NAME: 'TEACHER_GET_NAME',

    //IMAGES
    IMAGES_GET_LIST: 'IMAGES_GET_LIST',

    // EXAM MODULE  
    EXAM_GET_ALL: 'EXAM_GET_ALL',
    EXAM_REMOVE: 'EXAM_REMOVE',
    EXAM_UPDATE: 'EXAM_UPDATE',
    EXAM_ADD: 'EXAM_ADD',
    EXAM_GET_EXAM_BY_ID: 'EXAM_GET_EXAM_BY_ID',

    //QUESTION MODULE
    QUESTION_GET_ALL: 'QUESTION_GET_ALL',
    QUESTION_ADD: 'QUEST_ADD',
    QUESTION_REMOVE: 'QUESTION_REMOVE',
    QUESTION_UPDATE: 'QUESTION_UPDATE',
    QUESTION_GET_ONE_BY_ID: 'QUESTION_GET_ONE_BY_ID',
    
    //CLASSROOM MODULE
    CLASSROOM_GET_ALL: 'CLASSROOM_GET_ALL',
    CLASSROOM_ADD: 'CLASSROOM_ADD',
    CLASSROOM_REMOVE: 'CLASSROOM_REMOVE',
    CLASSROOM_UPDATE: 'CLASSROOM_UPDATE',
    CLASSROOM_GET_ONE_BY_ID: 'CLASSROOM_GET_ONE_BY_ID',
    CLASSROOM_GET_TEACHER_CLASS: 'CLASSROOM_GET_TEACHER_CLASS',
    CLASSROOM_GET_STUDENT_CLASS: 'CLASSROOM_GET_STUDENT_CLASS',
    CLASSROOMS_FROM_USEREXAMS: 'CLASSROOMS_FROM_USEREXAMS',
    CLASSROOM_GET_MYCLASSROOM_FOR_CLASSID: 'CLASSROOM_GET_MYCLASSROOM_FOR_CLASSID',

    //USEREXAM MODULE
    USEREXAM_GET_ALL: 'USEREXAM_GET_ALL',
    USEREXAM_REMOVE: 'USEREXAM_REMOVE',
    USEREXAM_UPDATE: 'USEREXAM_UPDATE',
    USEREXAM_CREATE: 'USEREXAM_CREATE',
    USEREXAM_GET_EXAM_BY_ID: 'USEREXAM_GET_EXAM_BY_ID',
    USEREXAM_GET_BY_CLASSID: 'USEREXAM_GET_BY_CLASSID',
    USEREXAM_GET_MY_EXAMS: 'USEREXAM_GET_MY_EXAMS',
    USEREXAM_GET_MY_EXAMS_IN_CLASS: 'USEREXAM_GET_MY_EXAMS_IN_CLASS',

    //COURSE MODULE
    COURSE_GET_ALL: 'COURSE_GET_ALL',
    COURSE_GET: 'COURSE_GET',
    COURSE_CREATE: 'COURSE_CREATE',
    COURSE_MODIFY: 'COURSE_MODIFY',
    COURSE_DELETE: 'COURSE_DELETE',
    COURSE_GET_TEACHER_COURSES: 'COURSE_GET_TEACHER_COURSES',
    COURSE_GET_STUDENT_COURSES: 'COURSE_GET_STUDENT_COURSES',
    COURSE_GET_MULTIPLE: 'COURSE_GET_MULTIPLE',
    COURSE_ADD_PDF: 'COURSE_ADD_PDF'
}