import Vue from 'vue'
import VueRouter from 'vue-router'
import cookies from 'vue-cookies'
//import Home from '../views/HomeView.vue'
import moment from 'moment'
import store from '@/store/store'

Vue.use(VueRouter)

const routes = [

  { path: '/', name: 'home', redirect: { name: 'dashboard' } },
  { path: '/login', name: 'login', component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue') },
  { path: '/dashboard', name: 'dashboard', component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue') },

  //Demo
  { path: '/lecture', name: 'opensea', component: () => import(/* webpackChunkName: "opensea" */ '../views/OpenSeaView.vue') },
  { path: '/images', name: 'images', component: () => import(/* webpackChunkName: "images" */ '../views/ImagesView.vue'), meta: { restricted: true, requiresTeacher: true }  },
  { path: '/deepzoom', name: 'deepzoom', component: () => import(/* webpackChunkName: "deepzoom" */ '../views/DeepZoomView.vue')  },

  //Admin
  { path: '/users', name: 'users', component: () => import(/* webpackChunkName: "users" */ '../views/admin/UsersView.vue'), meta: { restricted: true, requiresAdmin: true } },
  { path: '/courses', name: 'courses', component: () => import(/*webpackChunkName: "courses" */ '../views/admin/CoursesView.vue'), meta: { restricted: true, requiresAdmin: true } },
  { path: '/management', name: 'godmode', component: () => import(/*webpackChunkName: "godmode" */ '../views/admin/GodModeView.vue'), meta: { restricted: true, requiresAdmin: true } },

  //Mentor
  { path: '/mentor/exams/', name: 'examsmentor', component: () => import(/* webpackChunkName: "examsmentor" */ '../views/exams/ExamsMentorView.vue'), meta: { restricted: true, requiresTeacher: true } },
  { path: '/mentor/exams/creator', name: 'examcreator', component: () => import(/* webpackChunkName: "examcreator" */ '../views/exams/ExamCreateView.vue'), meta: { restricted: true, requiresTeacher: true } },
  // { path: '/mentor/exam/:id', name:'', component: () => import(/* webpackChunkName: "exam"*/ '../views/exams/ExamPreviewView.vue') },
  { path: '/mentor/questions', name: 'questions', component: () => import(/*webpackChunkName: "questions" */ '../views/QuestionsView.vue'), meta: { restricted: true, requiresTeacher: true } },
  { path: '/mentor/grades', name: 'gradesmentor', component: () => import(/* webpackChunkName: "gradesmentor" */ '../views/grade/GradesMentorView.vue'), meta: { restricted: true, requiresTeacher: true } },
  // { path: '/mentor/students/all', name: 'students', component: () => import(/* webpackChunkName: "students" */ '../views/students/StudentsListView.vue') },
  { path: '/mentor/classrooms', name: 'classroomsmentor', component: () => import(/* webpackChunkName: "classroomsmentor" */ '../views/classrooms/ClassroomsMentorView.vue'), meta: { restricted: true, requiresTeacher: true } },
  { path: '/mentor/classrooms/creator', name: 'classroomcreator', component: () => import(/* webpackChunkName: "classroomcreator" */ '../views/classrooms/ClassroomCreateView.vue'), meta: { restricted: true, requiresTeacher: true } },
  { path: '/mentor/exams/result', name: 'resultteacher', component: () => import(/* webpackChunkName: "resultteacher" */ '../views/exams/ExamsMentorResultsView.vue'), meta: { restricted: true, requiresTeacher: true } },
  { path: '/mentor/examImage/result/:idUserExam', name: 'examimagementor', component: () => import(/*webbpackChunkName: "examimagementor" */ '../views/exams/ExamImageResultView.vue'), meta: { restricted: true, requiresTeacher: true } },
  { path: '/mentor/examImage/preview/:idExam', name: 'examimagepreview', component: () => import(/*webbpackChunkName: "examimagepreview" */ '../views/exams/ExamImagePreviewView.vue'), meta: { restricted: true, requiresTeacher: true } },
  { path: '/mentor/courses', name: 'coursesmentor', component: () => import(/* webbpackChunkName: "coursesmentor" */ '../views/mentor/MentorCoursesView.vue'), meta: { restricted: true, requiresTeacher: true } },

  //Student  
  { path: '/student/exams/result', name: 'resultstudent', component: () => import(/* webpackChunkName: "studentsexams" */ '../views/exams/ExamsStudentView.vue'), meta: { restricted: true, requiresStudent: true } },
  { path: '/student/exams/:idClassroom', name: 'studentexam', component: () => import(/*webpackChunkName: "studentexam" */ '../views/exams/ExamQuizView.vue'), meta: { restricted: true, requiresStudent: true } },
  { path: '/student/grades', name: 'studentgrades', component: () => import(/* webpackChunkName: "grades" */ '../views/grade/GradesView.vue'), meta: { restricted: true, requiresStudent: true } },
  { path: '/student/classrooms', name: 'classroomstudent', component: () => import(/* webpackChunkName: "classroomstudent" */ '../views/classrooms/ClassroomsStudentView.vue'), meta: { restricted: true, requiresStudent: true } },
  { path: '/student/examImage/:idUserExam', name: 'examimagestudent', component: () => import(/*webbpackChunkName: "examimage" */ '../views/exams/ExamImageView.vue'), meta: { restricted: true, requiresStudent: true } },
  { path: '/student/examImage/start/:idClassroom', name: 'examimagestart', component: () => import(/*webbpackChunkName: "examimagestart" */ '../views/exams/ExamImageStartView.vue'), meta: { restricted: true, requiresStudent: true } },
  { path: '/student/examCombo/start/:idClassroom', name: 'examcombostart', component: () => import(/*webbpackChunkName: "examcombostart" */ '../views/exams/ExamComboStartView.vue'), meta: { restricted: true, requiresStudent: true } },
  { path: '/student/courses', name: 'studentcourses', component: () => import(/* webbpackChunkName: "studentcourses" */ '../views/student/StudentCoursesView.vue'), meta: { restricted: true, requiresStudent: true } },
  { path: '/student/examImage/result/:idUserExam', name: 'stuexamimagepreview', component: () => import(/*webbpackChunkName: "stuexamimagepreview" */ '../views/exams/ExamImageResultStudentView.vue'), meta: { restricted: true, requiresStudent: true } },

  //Mentor & Student
  { path: '/myaccount', name: 'myaccount', component: () => import(/* webpackChunkName: "myaccount" */ '../views/MyAccountView.vue') },

  {
    name: 'password-reset', path: '/reset/password',
    props(route) { return route.query || {} },
    component: () => import(/* webpackChunkName: "pass_reset" */ '../views/PassResetView.vue')
  },

  {
    name: 'password-request', path: '/reset/request',
    component: () => import(/* webpackChunkName: "pass_req" */ '../views/PassResetRequestView.vue')
  },
]

const router = new VueRouter({ routes })
const allowedAnonymous = ['login', 'password-reset', 'password-request']

router.beforeEach((to, from, next) => {

  if (allowedAnonymous.includes(to.name)) {
    next()
  }
  else {
    let cookie = cookies.get('AFM_SESSION');
    if (cookie) {
      if (moment().isAfter(moment(cookie.ends))) {
        cookies.remove('AFM_SESSION');
        next({ name: 'login' })
      }
      else {
        if (to.name === 'login') {
          next({ name: 'dashboard' })
        }
        else {
          next()
        }
      }
    }
    else {
      next({ name: 'login' })
    }
  }
})

router.beforeResolve((to, from, next) => {

  if (to.matched.some(record => record.meta.restricted)) {

    if (store.state.auth.session) {
      let roles = store.state.auth.session.roles

      if (roles.includes('Admin')) {
        next()
      } else if (roles.includes('Teacher')) {
        if (to.matched.some(record => record.meta.requiresTeacher)) {
          next()
        } else {
          next({ name: 'dashboard' })
        }
      } else if (roles.includes('Student')) {
        if (to.matched.some(record => record.meta.requiresStudent)) {
          next()
        } else {
          next({ name: 'dashboard' })
        }
      } else {
        next({ name: 'dashboard' })
      }

    } else {
      next({ name: 'dashboard' })
    }
  }
  else {
    next()
  }
})

export default router

