import { Action } from '../config_actions'
import { Apis } from '../config_apis'
//import { Mutation } from '../config_mutations'
import axios from 'axios'
//import cookies from 'vue-cookies'
//import moment from 'moment'

export default {
    namespaced: false,

    state: {
    },

    mutations:{

    },

    actions:{

        /**
         * Downloads userExams
         */        
        [Action.USEREXAM_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.USEREXAM_GET,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Download one userExams by id
         */        
        [Action.USEREXAM_GET_EXAM_BY_ID](context,payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.USEREXAM_BY_ID.replace('{id}',payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Add CREATE userExam
         */
        [Action.USEREXAM_CREATE](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.USEREXAM_CREATE,
                    headers: { Authorization: "Bearer " + token },
                    data: payload,
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },
        

        /**
         * Delete userExam
         */
        [Action.USEREXAM_REMOVE](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: Apis.USEREXAM_DELETE.replace('{id}',payload),
                    headers: { Authorization: "Bearer " + token }
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
         * Update userExam
         */
        [Action.USEREXAM_UPDATE](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'put',
                    url: Apis.USEREXAM_MODIFY.replace('{id}',payload.id),
                    headers: { Authorization: "Bearer " + token },
                    data:payload
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
         * Downloads UserExam in classroom
         */        
        [Action.USEREXAM_GET_MY_EXAMS_IN_CLASS](context,payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.USEREXAM_GET_MY_EXAMS_IN_CLASS.replace('{id}',payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },


        /**
         * Downloads my UserExams
         */

        [Action.USEREXAM_GET_MY_EXAMS](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.USEREXAM_GET_MY_EXAMS,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Downloads UserExam by classId
         */        
        [Action.USEREXAM_GET_BY_CLASSID](context,payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.USEREXAM_GET_BY_CLASSID.replace('{id}',payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },
    }
}