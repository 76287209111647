import { MLanguage } from 'vue-multilanguage'

var locals = loadLocaleMessages()
export default new MLanguage('english').create(locals)

function loadLocaleMessages() {
    const baseDictionary = require.context('./', false, /english.json$/i)
    const additionalDictionaries = require.context('./', false, /[A-Za-z0-9-_,\s]+\.json$/i)

    let dictionary = {}

    baseDictionary.keys().forEach(key => {
        dictionary = baseDictionary(key)
    })

    additionalDictionaries.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)

        if (matched && matched.length > 1) {
            const locale = matched[1]
            dictionary[locale] = additionalDictionaries(key)
        }
    })

    return dictionary
}