<template>
  <div class="is-inline">
    <b-tooltip
      type="is-primary"
      :label="t('feedback.tooltipLabel') + target"
      class="hand"
      :position= currentPosition
      multilined
    >
      <span @click="isComponentModalActive = true">
        <b-icon :type="{'is-primary':!isSecondary, 'is-secondary':isSecondary}" icon="space-invaders"></b-icon>
      </span>
    </b-tooltip>

    <!-- FEEDBACK MODAL -->
    <b-modal
      :active.sync="isComponentModalActive"
      has-modal-card
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <div class="modal-card">
        <header class="modal-card-head" :class="{'has-background-danger': payload.type===1}">
          <p class="modal-card-title" :class="{'has-text-light': payload.type===1}">
            {{t('feedback.header')}}:
            <strong :class="{'has-text-light': payload.type===1}">{{target}}</strong>
          </p>
        </header>
        <section class="modal-card-body">
          <b-field expanded :label="t('feedback.type')" label-position="on-border">
            <b-select expanded v-model="payload.type">
              <option :value="0">{{t('feedback.types.general')}}</option>
              <option :value="1">{{t('feedback.types.bigMistake')}}</option>
              <option :value="2">{{t('feedback.types.proposition')}}</option>
            </b-select>
          </b-field>
          <b-field :label="t('feedback.description')" label-position="on-border">
            <b-input
              :placeholder="t('feedback.message')"
              type="textarea"
              v-model="payload.description"
              maxlength="300"
            ></b-input>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="isComponentModalActive = false">{{t('common.close')}}</button>
          <b-button
            :disabled="payload.description.length < 10"
            :loading="loading"
            type="is-primary"
            @click="sendFeedback"
          >{{t('common.send')}}</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import CommonMixins from '../../mixins/commons'
import { Action } from '../../store/config_actions'

export default {
  name: 'AppointmentHistory',
  mixins: [CommonMixins],

  components: {

  },

  props: {
    target: { type: String, required: true },
    position: {type: String, required: false},
    isSecondary:{type: Boolean, required: false}
  },

  watch: {
  },

  computed:{
    currentPosition(){
      if(this.position) return this.position
      else return "is-bottom"
    }

  },

  mounted() {
  },

  data: function () {
    return {
      isComponentModalActive: false,
      loading: false,

      payload: {
        workerId: -1,
        type: 0,
        componentName: this.target,
        description: '',
        created: this.mNow(),
        isCompleted: false
      }
    }
  },

  methods: {
    sendFeedback() {
      this.loading = true
      this.payload.created = moment().format('YYYY-MM-DDTHH:mm:SS')
      this.payload.workerId = -1
      this.payload.componentName = 'AFM_' + this.payload.componentName

      this.$store
        .dispatch(Action.STATS_SEND_FEEDBACK, this.payload)
        .then(() => {
          this.mSuccess(this.t('feedback.sent'));
          this.loading = false
          this.isComponentModalActive = false
          this.payload.description = ''
          this.payload.type = 0
          this.payload.created = this.mNow()
        })
        .catch(error => {
          this.mDanger(error);
          this.loading = false
        });
    }
  }
}
</script>

<style scoped lang="scss">
.hand {
  cursor: pointer;
}
</style>
