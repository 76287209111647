import { Action } from '../config_actions'
import { Apis } from '../config_apis'
//import { Mutation } from '../config_mutations'
import axios from 'axios'
import { Mutation } from '../config_mutations'
//import cookies from 'vue-cookies'
//import moment from 'moment'

export default {
    namespaced: false,

    state: {
        teacherClassrooms: null,
        classroom:null
    },

    mutations: {

        [Mutation.CLASSROOMS_SET](state,payload){
            state.classrooms = payload;
        },
        
        [Mutation.CLASSROOMS_TEACHER_GET](state,payload){
            state.teacherClassrooms = payload;
        }

    },

    actions: {

        /**
        * Downloads classroom
        */
        [Action.CLASSROOM_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CLASSROOM_GET,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {                        
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Get one classroom
         */
        [Action.CLASSROOM_GET_ONE_BY_ID](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.CLASSROOM_BY_ID.replace('{id}', payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Add new classroom
         */
        [Action.CLASSROOM_ADD](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.CLASSROOM_CREATE,
                    headers: { Authorization: "Bearer " + token },
                    data: payload,
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
         * Delete classroom
         */
        [Action.CLASSROOM_REMOVE](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: Apis.CLASSROOM_DELETE.replace('{id}', payload),
                    headers: { Authorization: "Bearer " + token }
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
         * Update classroom
         */
        [Action.CLASSROOM_UPDATE](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'put',
                    url: Apis.CLASSROOM_MODIFY.replace('{id}', payload.id),
                    headers: { Authorization: "Bearer " + token },
                    data: payload
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
        * Downloads teacher classrooms
        */
        [Action.CLASSROOM_GET_MYCLASSROOM_FOR_CLASSID](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.CLASSROOM_GET_MYCLASSROOM_FOR_CLASSID.replace('{id}', payload),
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
        * Downloads teacher classrooms
        */
       [Action.CLASSROOM_GET_TEACHER_CLASS](context, payload) {
        return new Promise((resolve, reject) => {
            let token = context.rootState.auth.session.token
            if (token != null) {
                axios({
                    method: 'post',
                    url: Apis.CLASSROOM_GET_TEACHER_CLASS,
                    headers: { Authorization: "Bearer " + token },
                    data: payload
                }).then(response => {
                    context.commit(Mutation.CLASSROOMS_TEACHER_GET, response.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            } else reject('401')
        })
    },

        /**
        * Downloads student classrooms
        */
        [Action.CLASSROOM_GET_STUDENT_CLASS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.CLASSROOM_GET_STUDENT_CLASS,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        context.commit(Mutation.CLASSROOMS_SET, response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Download classroom when userexam exist
         */

        [Action.CLASSROOMS_FROM_USEREXAMS](context, payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'post',
                        url: Apis.CLASSROOMS_FROM_USEREXAMS,
                        headers: { Authorization: "Bearer " + token },
                        data: payload
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

    }
}