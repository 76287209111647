import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import axios from 'axios'
import { Mutation } from '../config_mutations'

export default {
    namespaced: false,

    state: {
        config: null,
        lastSelectedDeepzoom: null,
        lastPage: -1,
        isBack:false,
        lastFilters:null,
        lastScroll:0,
    },

    mutations:{
        [Mutation.DEEPZOOM_CONFIG_SET](state,payload){
            state.config = payload
        },

        [Mutation.DEEPZOOM_SET_LAST_SELECTED](state,payload){
            state.lastSelectedDeepzoom = payload
        },

        [Mutation.DEEPZOOM_RESET_LAST_SELECTED](state){
            state.lastSelectedDeepzoom = null
        },

        [Mutation.DEEPZOOM_SET_LAST_PAGE](state,page){
            state.lastPage = page
        },

        [Mutation.DEEPZOOM_RESET_LAST_PAGE](state){
            state.lastPage = -1
        },

        [Mutation.DEEPZOOM_SET_ISBACK](state,payload){
            state.isBack = payload
        },
        
        [Mutation.DEEPZOOM_RESET_ISBACK](state){
            state.isBack = false
        },

        [Mutation.DEEPZOOM_SET_FILTERS](state, filter){
            state.lastFilters = filter
        },

        [Mutation.DEEPZOOM_RESET_FILTERS](state){
            state.lastFilters = null
        },

        [Mutation.DEEPZOOM_SET_SCROLL](state, scrollY){
            state.lastScroll = scrollY
        },

        [Mutation.DEEPZOOM_RESET_SCROLL](state){
            state.lastScroll = 0
        },

        [Mutation.DEEPZOOM_FILTER_RESET_ALL](state){
            state.lastFilters = null
            //state.lastScroll = 0
            state.isBack = false
            state.lastPage = -1
            state.lastSelectedDeepzoom = null
        }

    },

    actions:{
 
         /**
          * Get DeepZoom config
          */
         [Action.DEEPZOOM_CONFIG_GET](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.DEEPZOOM_CONFIG_GET,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.DEEPZOOM_CONFIG_SET,response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },           
        
        /**
         * Create DeepZoom config
         */
        [Action.DEEPZOOM_CONFIG_CREATE](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.DEEPZOOM_CONFIG_CREATE,
                    headers: { Authorization: "Bearer " + token },
                    data: payload,
                }).then(response => {
                    context.commit(Mutation.DEEPZOOM_CONFIG_SET,response.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
         * Get DeepZoom config for group images
         */
        [Action.DEEPZOOM_CONFIG_GROUP](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.DEEPZOOM_CONFIG_GROUP,
                    headers: { Authorization: "Bearer " + token },
                    data: payload,
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },
    }
}