import { Action } from '../config_actions'
import { Apis } from '../config_apis'
import axios from 'axios'
import { Mutation } from '../config_mutations'
//import cookies from 'vue-cookies'
//import moment from 'moment'

export default {
    namespaced: false,

    state: {
        //names: []
        teachers: null
    },

    mutations: {
        // [Mutation.TEACHER_ADD](state, payload) {
        //     if (!state.names.includes(payload)) {
        //         state.names.push(payload);
        //     }
        // },
        [Mutation.USER_TEACHERS_SET](state,payload){
            state.teachers = payload;
        }

    },

    actions: {

        /**
        * Downloads teachers
        */
        [Action.TEACHER_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.TEACHER_GET_ALL,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        context.commit(Mutation.USER_TEACHERS_SET,response.data)
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Get name for userId
         */
        // [Action.TEACHER_GET_NAME](context, payload) {
        //     return new Promise((resolve, reject) => {
        //         let token = context.rootState.auth.session.token
        //         if (token != null) {
        //             let teacher = context.rootState.teacher.names.find((t) => t.id == payload)   
        //             console.log(context.rootState.teacher.names)                 
        //             if (teacher == null) {
        //                 //console.log("axios get")
        //                 axios({
        //                     method: 'get',
        //                     url: Apis.AUTH_GET_USER_NAME.replace('{id}', payload),
        //                     headers: { Authorization: "Bearer " + token }
        //                 }).then(response => {
        //                     const teacher = { id: payload, name: response.data }
        //                     context.commit(Mutation.TEACHER_ADD, teacher)
        //                     resolve(response.data)
        //                 }).catch(error => {
        //                     reject(error)
        //                 });
        //             } else {
        //                 resolve(teacher)
        //             }
        //         } else reject('401')
        //     })
        // },
    }
}