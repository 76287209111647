import { Action } from '../config_actions'
import { Apis } from '../config_apis'
//import { Mutation } from '../config_mutations'
import axios from 'axios'
//import cookies from 'vue-cookies'
//import moment from 'moment'

export default {
    namespaced: false,

    state: {
    },

    mutations:{

    },

    actions:{

        /**
         * Downloads exams
         */        
        [Action.EXAM_GET_ALL](context) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EXAM_GET,
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Downloads exams
         */        
        [Action.EXAM_GET_EXAM_BY_ID](context,payload) {
            return new Promise((resolve, reject) => {
                let token = context.rootState.auth.session.token
                if (token != null) {
                    axios({
                        method: 'get',
                        url: Apis.EXAM_BY_ID.replace('{id}',payload),
                        headers: { Authorization: "Bearer " + token }
                    }).then(response => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    });
                } else reject('401')
            })
        },

        /**
         * Add new exam
         */
        [Action.EXAM_ADD](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: Apis.EXAM_CREATE,
                    headers: { Authorization: "Bearer " + token },
                    data: payload,
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },
        

        /**
         * Delete exam
         */
        [Action.EXAM_REMOVE](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'delete',
                    url: Apis.EXAM_DELETE.replace('{id}',payload),
                    headers: { Authorization: "Bearer " + token }
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },

        /**
         * Update exam
         */
        [Action.EXAM_UPDATE](context, payload) {
            let token = context.rootState.auth.session.token
            return new Promise((resolve, reject) => {
                axios({
                    method: 'put',
                    url: Apis.EXAM_MODIFY.replace('{id}',payload.id),
                    headers: { Authorization: "Bearer " + token },
                    data:payload
                }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                });
            })
        },
    }
}