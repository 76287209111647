import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import authModule from './modules/auth'
import examModule from './modules/exam'
import questionModule from './modules/question'
import classroomModule from './modules/classroom'
import studentModule from './modules/students'
import userModule from './modules/userExam'
import imagesModule from './modules/images'
import teacherModule from './modules/teachers'
import courseModule from './modules/course'
import afmconfigModule from './modules/afmconfig'
import mediaModule from './modules/media'
import deepzoomModule from './modules/deepzoom'

export default new Vuex.Store({
  modules: {
    auth: authModule,
    exam: examModule,
    images: imagesModule,
    question: questionModule,
    classroom: classroomModule,
    student: studentModule,
    userExam: userModule,
    teacher: teacherModule,
    course: courseModule,
    afmconfig: afmconfigModule,
    media: mediaModule,
    deepzoom: deepzoomModule
  },
  state: {
  },
  mutations: {
  },
  actions: {

  },
})
