/**
 * This is an extension for vue-multilanguage for easier parameter replacement.
 * Regular vm translation with parameters includes 'with':
 * $ml.with('email', 'abc@gmail.com').with('user', 'Admin').get('some.path.email.message')
 * where some.path.email.message may look like 'This is a messasge for {email} from {user}'.
 * This extension allows to translate this way:
 * t('some.path.email.message', {email:'abc@gmail.com', user: 'Admin'}).
 * It's quite useful allowing multiple parameters in single options object
 * instead of multiple 'with' calls.
 */

export default {
  methods: {

    /** Gets localization string from specified path */
    t: function (path, params) {
      if (path) {
        try {
          // Get translation. This will be undefined if path is unknown.
          let translation = this.$ml.get(path)

          // Return translation or path (to help track missing translation)
          if (translation) {

            // Replace params with values.
            if (params) {
              for (let key in params) {
                let keyFormat = `{${key}}`
                translation = translation.replace(keyFormat, params[key])
              }
            }

            return translation
          }
          else {
            return `[${path}]`
          }
        } catch {
          return `[${path}]`
        }
      }
    }
  }
}
