<template>
  <div>
    <b-dropdown aria-role="list" position="is-bottom-left">
      <b-tooltip :label="t('common.font')" position="is-bottom" slot="trigger">
        <span class="hand">
          <b-icon type="is-secondary" icon="format-size"></b-icon>
        </span>
      </b-tooltip>

      <b-dropdown-item @click="size=1" aria-role="listitem" class="is-size-6">
        <b-icon icon="format-size" size="is-small" class="mr-2"></b-icon>{{t('common.fonts.small')}}
      </b-dropdown-item>
      <b-dropdown-item @click="size=2" aria-role="listitem" class="is-size-6">
        <b-icon icon="format-size" size="is-default" class="mr-2"></b-icon>{{t('common.fonts.normal')}}
      </b-dropdown-item>
      <b-dropdown-item @click="size=3" aria-role="listitem" class="is-size-6">
        <b-icon icon="format-size" size="is-medium" class="mr-2"></b-icon>{{t('common.fonts.big')}}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>

export default {
  name: 'FontSizeSelector',
  data() {
    return {
      size: 2
    }
  },

  watch: {
    size() {
      switch (this.size) {
        case 1:
          document.querySelector('html').style.fontSize = '14px'
          break
        case 3:
          document.querySelector('html').style.fontSize = '20px'
          break
        default:
          document.querySelector('html').style.fontSize = '16px'
          break
      }
    }
  },
}


</script>

<style scoped lang="scss">
</style>
