<template>
  <b-navbar class="card no-radius" type="is-primary" style="overflow: visible; font-size: 1.1rem;">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/dashboard' }">
        <div class="p-0 ml-3">
          <img
            v-if="x===0"
            src="logo/dd.svg"
            alt="AFM"
            style="height: 30px; margin-top: 2px; margin-left: 1rem; margin-right: 1rem;"
          />
          <!-- <img
            v-else-if="x===1"
            src="logo/r-02.svg"
            alt="AFM"
            style="height: 40px; margin-top: 5px;"
          />
          <img
            v-else-if="x===2"
            src="logo/r-03.svg"
            alt="AFM"
            style="height: 40px; margin-top: 5px;"
          />
          <img v-else src="logo/r-06.svg" alt="AFM" style="height: 40px; margin-top: 5px;" />-->
          <!-- <b-icon icon="book-open-page-variant-outline" size="is-medium" type="is-secondary"></b-icon> -->
        </div>
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-dropdown :label="t('navigation.resources')" class="ml-0 mr-0 pl-0 pr-0">
        <b-navbar-item href="#/images" style="font-size: 1.1rem;" v-if="isTeacher || isAdmin">
          <b-icon icon="image-album" class="mr-2"></b-icon>{{t('componentNames.multimedia')}}
        </b-navbar-item>
        <!-- <b-navbar-item href="#/lecture">
          <b-icon icon="image-search-outline" class="mr-2"></b-icon>Przeglądaj
        </b-navbar-item>-->
        <b-navbar-item href="#/deepzoom" style="font-size: 1.1rem;">
          <b-icon icon="database-outline" class="mr-2"></b-icon>{{t('componentNames.deepZoom')}}
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-dropdown :label="t('navigation.management')" v-if="isAdmin" class="ml-0 mr-0 pl-0 pr-0">
        <b-navbar-item href="#/users" style="font-size: 1.1rem;">
          <b-icon icon="account-multiple" class="mr-2"></b-icon>{{t('common.users')}}
        </b-navbar-item>
        <b-navbar-item href="#/courses" style="font-size: 1.1rem;">
          <b-icon icon="calendar" class="mr-2"></b-icon>{{t('common.courses')}}
        </b-navbar-item>
        <hr class="dropdown-divider" />
        <b-navbar-item href="#/management" style="font-size: 1.1rem;">
          <b-icon icon="cog" class="mr-2"></b-icon>{{t('componentNames.additionalOption')}}
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-dropdown :label="t('navigation.teacher')" v-if="isTeacher || isAdmin" class="ml-0 mr-0 pl-0 pr-0">
        <b-navbar-item href="#/mentor/classrooms" style="font-size: 1.1rem;">
          <b-icon icon="calendar" class="mr-2"></b-icon>{{t('componentNames.classrooms')}}
        </b-navbar-item>
        <b-navbar-item href="#/mentor/exams/result" style="font-size: 1.1rem;">
          <b-icon icon="newspaper-variant-outline" class="mr-2"></b-icon>{{t('componentNames.exams')}}
        </b-navbar-item>
        <b-navbar-item href="#/mentor/courses" style="font-size: 1.1rem;">
          <b-icon icon="view-dashboard-outline" class="mr-2"></b-icon>{{t('common.courses')}}
        </b-navbar-item>
        <hr class="dropdown-divider" />
        <b-navbar-item href="#/mentor/exams" style="font-size: 1.1rem;">
          <b-icon icon="text-box-plus-outline" class="mr-2"></b-icon>{{t('componentNames.mentorExams')}}
        </b-navbar-item>
        <b-navbar-item href="#/mentor/questions" style="font-size: 1.1rem;">
          <b-icon icon="package-variant" class="mr-2"></b-icon>{{t('componentNames.questions')}}
        </b-navbar-item>
        <hr class="dropdown-divider" />
        <b-navbar-item href="#/myaccount" style="font-size: 1.1rem;">
          <b-icon icon="account" class="mr-2"></b-icon>{{t('componentNames.myAccount')}}
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-dropdown :label="t('navigation.student')" v-if="isStudent || isAdmin" class="ml-0 mr-0 pl-0 pr-0">
        <b-navbar-item href="#/student/classrooms" style="font-size: 1.1rem;">
          <b-icon icon="calendar" class="mr-2"></b-icon>{{t('componentNames.studentClassrooms')}}
        </b-navbar-item>
        <b-navbar-item href="#/student/courses" style="font-size: 1.1rem;">
          <b-icon icon="view-dashboard-outline" class="mr-2"></b-icon>{{t('componentNames.studentCourses')}}
        </b-navbar-item>
        <b-navbar-item href="#/student/exams/result" style="font-size: 1.1rem;">
          <b-icon icon="newspaper-variant-outline" class="mr-2"></b-icon>{{t('componentNames.studentResults')}}
        </b-navbar-item>
        <hr class="dropdown-divider" />
        <b-navbar-item href="#/myaccount" style="font-size: 1.1rem;">
          <b-icon icon="account" class="mr-2"></b-icon>{{t('componentNames.myAccount')}}
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>

    <template slot="end">
      <b-navbar-item tag="div">
        <p class="has-text-right" style="line-height: 1rem;">
          <span class="has-text-secondary mr-1 is-uppercase has-text-weight-semibold">{{fullName}}</span>
          <br />
          <span class="has-text-light is-size-7">{{mGetRoleTypesName(myRoles)}}</span>
          <b-tooltip
            type="is-light"
            :label="`${t('auth.alert')} ${timerText}`"
            position="is-left"
          >
            <p class="is-size-7 has-text-centered" style="width: 4rem;">({{timerText}})</p>
          </b-tooltip>
        </p>
      </b-navbar-item>
      <b-navbar-item tag="div">
        <UserFeedback :target="$options.name" :isSecondary="true" class="mr-4 mt-1 is-inline"></UserFeedback>
        <FontSizeSelector class="mr-4 mt-1 is-inline" style="cursor: pointer;"></FontSizeSelector>
        <RegulationInfo class="pr-4 mt-1 is-inline" style="cursor: pointer;"></RegulationInfo>
        <LanguageDropdown class="pr-5 pl-1 mt-1 is-inline" style="cursor: pointer;"></LanguageDropdown>
        <!-- <b-select rounded v-model="x" size="is-small" class="mr-1">
          <option :value="0">1</option>
          <option :value="1">2</option>
          <option :value="2">3</option>
          <option :value="5">4</option>
        </b-select>-->
        <b-button
          rounded
          :loading="isLoading"
          @click="logout"
          size="is-small"
          type="is-secondary"
          icon-right="exit-to-app"
          class="has-text-weight-bold mr-3"
        >{{t('auth.logout')}}</b-button>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import { Action } from "../../store/config_actions";
import Commons from "@/mixins/commons";
import RoleHelpers from "@/mixins/roles";
import UserFeedback from '../feedbacks/UserFeedback';
import FontSizeSelector from './FontSizeSelector';
import RegulationInfo from './RegulationInfo';
import LanguageDropdown from "@/components/tools/LanguageDropdownNav";

export default {
  name: "SiteNavigation",
  mixins: [Commons, RoleHelpers],
  components: {
    UserFeedback,
    FontSizeSelector,
    RegulationInfo,
    LanguageDropdown
  },

  data: function () {
    return {
      isLoading: false,
      timerText: null,
      x: 0
    };
  },

  props: {
    activity: {
      type: Number, required: true
    }
  },

  watch: {
    activity(val) {
      if (val) {
        let m = Math.floor(val / 60)
        let sec = val % 60

        this.timerText = `${m}m ${sec}s`
      }
      else {
        this.timerText = null
      }
    },
  },

  computed: {

    session() {
      return this.$store.state.auth.session;
    },

    isAdmin() {
      if (this.session && this.session.roles.includes("Admin")) return true;
      else return false;
    },

    isTeacher() {
      if (this.session && this.session.roles.includes("Teacher")) return true;
      else return false;
    },

    isStudent() {
      if (this.session && this.session.roles.includes("Student")) return true;
      else return false;
    },

    fullName() {
      if (this.session) {
        return this.session.firstName + " " + this.session.lastName;
      } else return ""
    },

    myRoles() {
      if (this.session && this.session.roles) return this.session.roles;
      else return [];
    },
  },

  methods: {
    logout() {

      this.$buefy.dialog.confirm({
        message: this.t('auth.logoutMessage'),
        hasIcon: true,
        type: 'is-danger',
        scroll: 'keep',
        cancelText: this.t('common.cancel'),
        confirmText: this.t('auth.logoutConfirm'),
        onConfirm: () => {
          this.isLoading = true;
          this.$store
            .dispatch(Action.AUTH_LOGOUT)
            .then(() => {
              this.isLoading = false;
              this.$cookies.remove("AFM_SESSION");
              this.$router.push({ name: "login" });
            })
            .catch((error) => {
              this.isLoading = false;
              this.mDangerSnack(error.toString());
            });
        }
      })
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
</style>
