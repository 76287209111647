export const Mutation = {

    // IDENTITY MODULE
    GENERAL_COOKIES_ACCEPT: 'GENERAL_COOKIES_ACCEPT',
    AUTH_START_SESSION: 'AUTH_START_SESSION',
    AUTH_END_SESSION: 'AUTH_END_SESSION',
    AUTH_RESET_ACTIVITY: 'AUTH_RESET_ACTIVITY',
    AUTH_LOWER_ACTIVITY: 'AUTH_LOWER_ACTIVITY',
    //AUTH_SET_USER: 'AUTH_SET_USER'


    //AFM_CONFIG
    AFM_CONFIG_SET: 'AFM_CONFIG_SET',

    //DEEPZOOM_CONFIG
    DEEPZOOM_CONFIG_SET: 'DEEPZOOM_CONFIG_SET',
    DEEPZOOM_SET_LAST_SELECTED: 'DEEPZOOM_SET_LAST_SELECTED',
    DEEPZOOM_RESET_LAST_SELECTED: 'DEEPZOOM_RESET_LAST_SELECTED',
    DEEPZOOM_SET_LAST_PAGE: 'DEEPZOOM_SET_LAST_PAGE',
    DEEPZOOM_RESET_LAST_PAGE: 'DEEPZOOM_RESET_LAST_PAGE',
    DEEPZOOM_SET_ISBACK: 'DEEPZOOM_SET_ISBACK',
    DEEPZOOM_RESET_ISBACK: 'DEEPZOOM_RESET_ISBACK',
    DEEPZOOM_SET_FILTERS: 'DEEPZOOM_SET_FILTERS',
    DEEPZOOM_RESET_FILTERS: 'DEEPZOOM_RESET_FILTERS',
    DEEPZOOM_SET_SCROLL: 'DEEPZOOM_SET_SCROLL',
    DEEPZOOM_RESET_SCROLL: 'DEEPZOOM_RESET_SCROLL',
    DEEPZOOM_FILTER_RESET_ALL:'DEEPZOOM_FILTER_RESET_ALL',

    //MODULE   
    TEACHER_ADD: 'TEACHER_ADD',

    //CLASSROOM
    CLASSROOMS_SET:'CLASSROOMS_SET',
    CLASSROOMS_MY_SET:'CLASSROOMS_MY_SET',
    CLASSROOMS_TEACHER_GET:'CLASSROOMS_TEACHER_GET',

    //MEDIA
    MEDIA_SET_MY:'MEDIA_SET_MY',
    MEDIA_SET:'MEDIA_SET',

    //USER
    USER_STUDENTS_SET:'USER_STUDENTS_SET',
    USER_TEACHERS_SET:'USER_TEACHERS_SET',
    USERS_SET: 'USERS_SET'





};